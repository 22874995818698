.legendBubble {
  border-radius: 50%;
  text-align: center;
  height: 1em;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1em;
  margin-right: 0.5em;
}

.legendContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 25%;
}

.legendContainer>div {
  flex: 0 50%;

}
