

.row {
  display: flex;
  flex-direction: row;
}
.column {
  display: flex;
  flex-direction: column;
  width: 32%;
  padding: 1em;
}
.zoneDiv {
  width: 50%;
}
.mvDiv {
  width: 50%;
}

