.mainDiv {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 3rem;
}

.spanRow {
  display: flex;
  justify-content: space-between;
}

.percentageSpan {
  display: flex;
  justify-content: space-between;
  width: 20%
}
.percentageCell {
  display: flex;
  justify-content: space-between;
}
