.alert {
  position: fixed;
  right: 2em;
  bottom: 2em;
  padding: 1em;
  font-size: 1.5em;
}
.error {
}

.info {
}

