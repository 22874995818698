.table {
  width: 100%;
  text-align: center;
}

.button-container {
}
.edit-user-content {
  display: flex;
  flex-direction: column;
  width: 50%;
}

.edit-user-content > p,
.edit-user-content > div {
  margin-bottom: 5px;
}

.filter-wrapper {
  margin: 1em auto;
  width: 100%;
  padding: 1em;
  display: flex;
  flex-direction: column;
}

.autocomplete-input {
  margin-left: 4px;
}

.new-user-btn {
  cursor: pointer;
  opacity: 0.8;
}

.redIcon {
  color: red;
}
