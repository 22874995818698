.topRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.mainRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 2rem;
}
.mainRowChild {
  width: 30%;
}

.listChild {
  width: 30%;
  display: flex;
  flex-direction: column;
}
.scrollableList {
  max-height: 15rem;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
  padding-left: 5px;
}

