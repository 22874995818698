.container {
}

.loadingContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.loadingText {
}
